<template>
    <div class="my-container">
        <TopCard :origin="userStore.externalParamsArray[0]" :process="userStore.externalParamsArray[3]"/>
        <div class="content-container">
            <div class="has-text-weight-bold has-text-primary font-size-18 has-text-centered">
                Je crée mon compte
            </div>
            <!-- Button "J'ai déjà un compte" -->
            <div
                class="is-clickable text-blue-2 has-text-centered font-size-14 has-text-weight-bold mb-5"
                style="text-decoration: underline;"
                @click="gotAccountAlready"
            >
                J'ai déjà un compte
            </div>
            <form @submit.prevent="prm.length == 14 ? createAccount() : searchEnedisPrm()" style="flex-grow: 1;">
                <div class="flexcol" style="justify-content: space-between; height: 100%;">
                    <div>
                        <!-- Input LASTNAME -->
                        <div class="control mb-3">
                            <input
                                class="input login-v2-input"
                                :class="{'dangerous': errorSet.lastname != null}"
                                style=""
                                v-model="lastname"
                                type="text"
                                placeholder="Nom"
                                name="lastname"
                                :disabled="isFormDisabled"
                            >
                            <p v-if="errorSet.lastname" class="text-error ml-2 font-size-12 mt-1 line-height-1-1">{{ errorSet.lastname }}</p>
                        </div>

                        <!-- Input FIRSTNAME -->
                        <div class="control mb-3">
                            <input
                                class="input login-v2-input"
                                :class="{'dangerous': errorSet.firstname != null}"
                                v-model="firstname"
                                type="text"
                                placeholder="Prénom"
                                name="firstname"
                                :disabled="isFormDisabled"
                            >
                            <p v-if="errorSet.firstname" class="text-error ml-2 font-size-12 mt-1 line-height-1-1">{{ errorSet.firstname }}</p>
                        </div>

                        <!-- Input EMAIL -->
                        <div class="control mb-3">
                            <input
                                class="input login-v2-input"
                                :class="{'dangerous': errorSet.email != null }"
                                v-model="email"
                                type="text"
                                placeholder="Email"
                                name="email"
                                autocapitalize="off"
                                :disabled="isFormDisabled && errorSet.email == null"
                            >
                            <p v-if="errorSet.email" class="text-error ml-2 font-size-12 mt-1 line-height-1-1">{{ errorSet.email }}</p>
                        </div>

                        <!-- Input ADDRESS -->
                        <div class="control">
                            <input
                                class="input login-v2-input"
                                :class="{'dangerous': errorSet.address != null }"
                                v-model="address"
                                @input="searchAddress"
                                type="text"
                                placeholder="Adresse complète"
                                name="address"
                                :disabled="isFormDisabled"
                            >
                            <p v-if="errorSet.address" class="text-error ml-2 font-size-12 mt-1 line-height-1-1">{{ errorSet.address }}</p>
                        </div>
                        <span class="text-blue-2 has-text-weight-medium font-size-14 mt-1">Utilisée pour localiser votre compteur.</span>

                        <!-- Ban address search result -->
                        <article
                            v-if="address != '' && selectedBanAddress == null"
                            class="panel"
                        >
                            <div class="panel-block is-clickable" v-for="(a, index) in banSearch.results" @click="selectBanAdress(index)">
                                <span class="panel-icon">
                                    <font-awesome-icon icon="fa-solid fa-location-dot" />
                                </span>
                                {{a.properties.name}}<br>
                                {{a.properties.postcode}} {{a.properties.city}}
                            </div>

                            <div
                                v-if="banSearch.results.length == 0 && !loadingAddress && address != '' && address.trim().length >= 3"
                                class="panel-block p-5"
                            >
                                Aucune adresse trouvée
                            </div>
                            <div class="panel-block p-5" v-if="loadingAddress">
                                <button class="button is-loading is-white"></button>
                                <span class="ml-2">recherche en cours...</span>
                            </div>
                        </article>

                        <!-- Prm found or not card -->
                        <div class="panel-block p-5" style="border: none;" v-if="loadingPrm">
                            <button class="button is-loading is-white"></button>
                            <span class="ml-2 line-height-1-1">Nous cherchons votre compteur...</span>
                        </div>
                        
                        <PrmNotFound v-if="isPrmNotFound" :reason="searchEnedisErrorType"/>

                        <!-- Input PRM -->
                        <div v-if="prmFound == false && !loadingPrm" class="mt-3 has-text-primary has-text-weight-bold font-size-18">
                            Renseignez votre n° de compteur
                        </div>
                        <div v-if="prmFound == false && !loadingPrm" class="control has-icons-right mt-3 mb-3">
                            <input
                                class="input login-v2-input"
                                :class="{'dangerous': errorSet.prm != null }"
                                v-model="prm"
                                type="text"
                                placeholder="Numéro de compteur"
                                name="prm"
                            >
                            <div
                                class="icon is-right is-clickable"
                                style="height: 48px; width: 48px; padding-right: 20px;"
                                @click="openModalHelpToFindPrm"
                            >
                                <img src="/src/assets/img/info_icon.svg" style="height: 26px; width: 26px;"/>
                            </div>
                            <p v-if="errorSet.prm" class="text-error ml-2 font-size-12 mt-1 line-height-1-1">{{ errorSet.prm }}</p>
                        </div>

                        <PrmFound
                            v-if="prmFound == true || prm.length == 14"
                            :has-custom-prm-text="prmFound == false && prm.length == 14"
                            :errors="errorSet"
                            :origin="userStore.externalParamsArray[0]"
                            :process="userStore.externalParamsArray[3]"
                            :has-partner-checkbox="userStore.hasPartnerCheckbox"
                            @set-cgu="setCgu"
                            @set-consent="setConsent"
                            @set-partner-consent="setPartnerConsent"
                            @open-cgu="showModalCgu = true;"
                        />
                            
                        <!-- General errors are displayed here -->
                        <div v-if="backendError != null" class="text-error font-size-16 mt-4 has-text-centered line-height-1-1">
                            {{ backendError }}
                        </div>
                    </div>

                    <!-- Button "Trouver mon compteur électrique/Je m'inscris" -->
                    <div style="width: 100%; padding-top: 30px;">
                        <button
                            type="submit"
                            class="radius-10 height-48 is-fullwidth button is-primary has-text-weight-bold"
                            :class="{ 'is-loading': loadingCreateAccount || loadingPrm }"
                        >
                            <span v-if="prm.length == 14">Je m'inscris</span>
                            <span v-else>Trouver mon compteur électrique</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>

        <ModalCgu :active="showModalCgu" @toggle-modal-cgu="showModalCgu = !showModalCgu"/>
        <ModalHelpToFindPrm :active="showModalHelpToFindPrm" @toggle-info="showModalHelpToFindPrm = !showModalHelpToFindPrm"/>
    </div>
</template>

<script setup>
import { ref, reactive, watch, onBeforeMount, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import API from '@/services/api';
import { debounce } from '@/utils';
import ModalHelpToFindPrm from '@/components/modals/ModalHelpToFindPrm.vue';
import ModalCgu from '@/components/modals/ModalCgu.vue';
import PrmFound from '@/components/auth/PrmFound.vue';
import PrmNotFound from '@/components/auth/PrmNotFound.vue';
import TopCard from '@/components/auth/TopCard.vue';

const route = useRoute()
const router = useRouter()
const userStore = useUserStore()

const loadingAddress = ref(false)
const loadingPrm = ref(false)
const loadingCreateAccount = ref(false)
const showModalCgu = ref(false)
const showModalHelpToFindPrm = ref(false)

const firstname = ref('')
const lastname = ref('')
const email = ref('')
const address = ref('')
const cgu = ref(false)
const consent = ref(false)
const prm = ref('')
const fullname = ref('')
const partnerConsent = ref(false)

const banSearch = ref({results:[]})
const selectedBanAddress = ref(null)

const prmFound = ref(null)
const isFormDisabled = ref(false)
const firstAttemptDone = ref(false)
const searchEnedisErrorType = ref("")

const errorSet = reactive({
    'lastname': null,
    'firstname': null,
    'email': null,
    'address': null,
    'cgu': null,
    'consent': null,
    'prm': null,
})
const backendError = ref(null)

const resetPrm = () => {
    cgu.value = null
    consent.value = null
}

watch(prm, async(newOne, oldOne) => {
    if (newOne.length != 14) resetPrm()
})

watch(lastname, async(newOne, oldOne) => {
    if (firstAttemptDone.value) debouncedLastName()
})

const isPrmNotFound = computed(() => {
    return prmFound.value == false && prm.value.length == 0 && !loadingPrm.value
})

const gotAccountAlready = () => {
    userStore.trackEvent('click_login_link', {'page': route.path, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
    router.push({name: 'ConnectionLink'})
}

const setCgu = (checked) => {
    cgu.value = checked
    if (checked) {
        userStore.trackEvent('check_terms', {'page': route.path, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
    }
}

const setConsent = (checked) => {
    consent.value = checked
    if (checked) {
        userStore.trackEvent('check_wattwatchers', {'page': route.path, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
    }
}

const setPartnerConsent = (checked) => {
    partnerConsent.value = checked
    if (checked) {
        userStore.trackEvent(
            'check_partner', {
                'page': route.path,
                'origine': userStore.externalParamsArray[0],
                'parcours': userStore.externalParamsArray[3]
            }
        )
    }
}

const openModalHelpToFindPrm = () => {
    showModalHelpToFindPrm.value = true
    userStore.trackEvent('click_find_meter_information', {'page': route.path, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
}

const debouncedSearchAddress = debounce(1000, async function(){
    try {
        let resp = await API.searchAddressFromBan(address.value)
        // console.log("RESP BAN", resp)
        banSearch.value.results = resp.data.features
        loadingAddress.value = false
    } catch (e) {
        console.log("RESP BAN ERROR", e)
    }
})

// In case of failure to find a prm with first attempt address and name
// we have to automatically call enedis to find prm if there is a new name
const debouncedLastName = debounce(1000, async function(){
    if (lastname.value.trim() != '' && lastname.value.length >= 2) {
        searchEnedisPrm()
    }
})

async function searchAddress(){
    prm.value = ''
    selectedBanAddress.value = null
    if(address.value.trim().length < 3){
        return
    }
    loadingAddress.value = true
    debouncedSearchAddress()
}

async function selectBanAdress(index){
    let selected = banSearch.value.results[index]
    console.log("SELECTED ADDRESS", selected)
    selectedBanAddress.value = selected
    address.value = selected.properties.label

    // In case of failure to find a prm with first attempt address and name
    // we have to automatically call enedis to find prm if there is a new address
    if (firstAttemptDone.value) {
        searchEnedisPrm()
    }
}

const validateDatas = (isCreation) => {
    console.log("validateDatas", isCreation)
    if (lastname.value.trim().length < 2) errorSet.lastname = "Le nom doit être de 2 caractères minimum"
    else errorSet.lastname = null
    if (firstname.value.trim().length < 2) errorSet.firstname = "Le prénom doit être de 2 caractères minimum"
    else errorSet.firstname = null
    if (email.value.trim().length < 6) errorSet.email = "Veuillez mettre un email valide"
    else errorSet.email = null
    if (selectedBanAddress.value == null || selectedBanAddress.value.properties.name.trim().length == 0) errorSet.address = "Veuillez sélectionner une adresse dans la liste déroulante"
    else errorSet.address = null
    if (isCreation) {
        console.log("isCreation", isCreation)
        console.log("cgu", cgu.value)
        console.log("consent", consent.value)
        if (!cgu.value) errorSet.cgu = "Veuillez cocher cette case si vous désirez poursuivre"
        else errorSet.cgu = null
        if (!consent.value) errorSet.consent = "Veuillez cocher cette case si vous désirez poursuivre"
        else errorSet.consent = null
        if (prm.value.trim().length != 14) errorSet.prm = "Un numéro de PRM ou point de livraison comporte 14 chiffres"
        else errorSet.prm = null
    }
    console.log("validateDatas errorSet", errorSet)
}

const searchEnedisPrm = async() => {
    console.log("searchEnedisPrm")
    
    let formIsOk = true

    validateDatas(false)

    Object.values(errorSet).forEach(err => {
        console.log("ERR", err)
        if(err != null) formIsOk = false
    });

    if (formIsOk) {
        backendError.value = null
        loadingPrm.value = true
        try {
            let resp = await API.searchEnedisPrm(
                firstname.value,
                lastname.value,
                selectedBanAddress.value.properties.name,
                selectedBanAddress.value.properties.citycode,
                selectedBanAddress.value.properties.postcode,
            )
            if (resp.status == 200) {
                firstAttemptDone.value = true
                console.log("SEARCH ENEDIS PRM 200", resp.data)
                // let ok = resp.data.ok
                let errors = resp.data.errors
                let data = resp.data.data
                if (data['prm'] != '' && data['prm'] != null) {
                    console.log("UPDATE PRM")
                    prmFound.value = true
                    prm.value = data['prm']
                    fullname.value = data['full_name']
                    isFormDisabled.value = true
                    console.log("UPDATE PRM", prm.value)
                    console.log("UPDATE PRM", fullname.value)

                    userStore.trackEvent('PreRegistration1')
                    userStore.trackEvent(
                        'click_find_meter', {'success': true, 'page': route.path, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]}
                    )
                } else {
                    console.log("!!! NO PRM FOUND", errors)
                    prmFound.value = false
                    prm.value = ''
                    fullname.value = ''
                    isFormDisabled.value = false

                    let errorDetails = errors.join(", ")
                    searchEnedisErrorType.value = "enedis_called_no_prm_found"
                    if (errorDetails.indexOf("PRM already in DB") >= 0) {
                        searchEnedisErrorType.value = "enedis_called_prm_already_in_db"
                    }
                    userStore.trackEvent(
                        'click_find_meter', {
                            'success': false,
                            'page': route.path,
                            'origine': userStore.externalParamsArray[0],
                            'parcours': userStore.externalParamsArray[3],
                            'error_type': searchEnedisErrorType.value,
                            'error_details': errorDetails
                        }
                    )
                }
            }
            console.log("SEARCH ENEDIS PRM RESP : ", resp)
        } catch (e) {
            console.log("Erreur: searchEnedisPrm() -> ", e)
            let errorDetails = []
            if (e.response.status == 400) {
                // errors from form validation
                if (e.response.data.last_name) {
                    errorSet.lastname = e.response.data.last_name.join(", ")
                }
                if (e.response.data.first_name) {
                    errorSet.firstname = e.response.data.first_name.join(", ")
                }
                if (e.response.data.non_field_errors) {
                    backendError.value = e.response.data.non_field_errors.join(", ")
                }
                errorDetails.push(e.response.data)
            } else {
                if (typeof e.response.data === "string" && e.response.data.indexOf("<!DOCTYPE html>") >= 0) {
                    backendError.value = `Une erreur ${e.response.status} est survenue, veuillez réessayer ultérieurement.`
                    errorDetails.push(`Une erreur backend de statut ${e.response.status} est survenue. Plus d'infos dans les outils de monitoring.`)
                }
                else {
                    backendError.value = JSON.stringify(e.response.data)
                    errorDetails.push(backendError.value)
                }
            }
            userStore.trackEvent(
                'click_find_meter', {
                    'success': false,
                    'page': route.path,
                    'origine': userStore.externalParamsArray[0],
                    'parcours': userStore.externalParamsArray[3],
                    'error_type': 'backend_error',
                    "error_details": JSON.stringify(errorDetails)
                }
            )
        }
        loadingPrm.value = false
    } else {
        // concatenate all errors in the form
        // let errorDetails = Object.values(errorSet).join(", ")
        let errorDetails = []
        Object.values(errorSet).forEach(err => {
            if(err != null) {
                errorDetails.push(err)
            }
        });
        errorDetails = errorDetails.join(", ")
        console.log("frontend_error details:", errorDetails)
        userStore.trackEvent(
            'click_find_meter', {
                'success': false,
                'page': route.path,
                'origine': userStore.externalParamsArray[0],
                'parcours': userStore.externalParamsArray[3],
                'error_type': 'frontend_error',
                'error_details': errorDetails
            }
        )
    }
}

const createAccount = async () => {
    console.log("createAccount -> ", lastname.value)
    
    let formIsOk = true

    validateDatas(true)

    Object.values(errorSet).forEach(err => {
        console.log("ERR", err)
        if(err != null) formIsOk = false
    });

    console.log("FORM IS OK", formIsOk)
    console.log("ERRORS", errorSet)

    if (formIsOk) {
        console.log("LET'S GO", userStore.externalParams)
        backendError.value = null
        loadingCreateAccount.value = true

        let [origin, utm, partner, process] = userStore.externalParamsArray
        console.log("EXTERNAL PARAMS: ", origin, utm, partner, process)

        if (origin != null && partner != null && Object.keys(partner).includes('partner_user_id')) partner['consent'] = partnerConsent.value
        
        try {
            let resp = await API.createAccount(
                lastname.value,
                firstname.value,
                email.value,
                selectedBanAddress.value.properties.name,
                selectedBanAddress.value.properties.citycode,
                selectedBanAddress.value.properties.postcode,
                cgu.value,
                consent.value,
                prm.value,
                fullname.value,
                process,
                origin,
                utm,
                partner
            )
            console.log("RESPONSE createAccount", resp)
            if (resp.status == 201) {
                console.log("createAccount DONE", resp.data["account"])
                console.log("createAccount DONE", resp.data["account"].has_functional_prm)
                await userStore.update_tokens(resp.data["access"], resp.data["refresh"])
                await userStore.update_profile()

                userStore.trackEvent('CompleteRegistration1')
                userStore.trackEvent(
                    'click_sign_up', {'success': true, 'page': route.path, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]}
                )

                if (!resp.data["account"].has_functional_prm) {
                    goToUnfunctionalPrmScreen()
                } else {
                    router.push({ name: "SignupDone" })
                }
            }
        } catch (e) {
            console.log("Erreur: createAccount -> ", e.response.data)
            if (e.response.status == 400) {
                if (Array.isArray(e.response.data)) {
                    backendError.value = e.response.data.join(", ")
                    if (backendError.value.includes("prm_already_used")) {
                        backendError.value = "Désolé, ce PRM est déjà utilisé par un autre compte. Pouvez-vous vérifier auprès des autres personnes de votre foyer qu’ils n’ont pas déjà créé un compte ?"
                        userStore.trackEvent(
                            'click_sign_up', {
                                'success': false,
                                'page': route.path,
                                'origine': userStore.externalParamsArray[0],
                                'parcours': userStore.externalParamsArray[3],
                                'error_type': 'backend_error_prm_already_in_db',
                                'error_details': "prm already in db"
                            }
                        )
                    }
                    else if (backendError.value.includes("SGT401")) {
                        backendError.value = "Désolé, ce PRM n'est pas valide"
                        userStore.trackEvent(
                            'click_sign_up', {
                                'success': false,
                                'page': route.path,
                                'origine': userStore.externalParamsArray[0],
                                'parcours': userStore.externalParamsArray[3],
                                'error_type': 'backend_error_enedis_error_prm_not_valid',
                                'error_details': 'SGT401: Demande non recevable : point inexistant.'
                            }
                        )
                    }
                    else if (backendError.value.includes("SGT")) {
                        userStore.trackEvent(
                            'click_sign_up', {
                                'success': false,
                                'page': route.path,
                                'origine': userStore.externalParamsArray[0],
                                'parcours': userStore.externalParamsArray[3],
                                'error_type': 'backend_error_enedis_error',
                                'error_details': backendError.value
                            }
                        )
                    }
                    else {
                        userStore.trackEvent(
                            'click_sign_up', {
                                'success': false,
                                'page': route.path,
                                'origine': userStore.externalParamsArray[0],
                                'parcours': userStore.externalParamsArray[3],
                                'error_type': 'backend_error',
                                'error_details': backendError.value
                            }
                        )
                    }
                } else {
                    let backendResponse = {...e.response.data}
                    // errors from form validation
                    if (backendResponse.email) {
                        errorSet.email = backendResponse.email.join(", ")
                        delete backendResponse.email
                    }
                    if (backendResponse.lastname) {
                        errorSet.lastname = backendResponse.lastname.join(", ")
                        delete backendResponse.lastname
                    }
                    if (backendResponse.firstname) {
                        errorSet.firstname = backendResponse.firstname.join(", ")
                        delete backendResponse.firstname
                    }
                    if (backendResponse.non_field_errors) {
                        backendError.value = backendResponse.non_field_errors.join(", ")
                        delete backendResponse.non_field_errors
                    }
                    if (backendResponse.error == "Cette adresse email est déjà utilisée") {
                        errorSet.email = "Un compte existe déjà avec cette adresse mail."
                        backendError.value = ''
                        delete backendResponse.error
                    }
                    if (backendResponse.error) {
                        backendError.value = backendResponse.error
                        delete backendResponse.error
                    }
                    
                    // convert the data back to string to track errors
                    // TODO: extract more details here
                    if (backendError.value == null) backendError.value = JSON.stringify(e.response.data)
                    userStore.trackEvent(
                        'click_sign_up', {
                            'success': false,
                            'page': route.path,
                            'origine': userStore.externalParamsArray[0],
                            'parcours': userStore.externalParamsArray[3],
                            'error_type': 'backend_error',
                            'error_details': JSON.stringify(e.response.data)
                        }
                    )
                }
            } else {
                let errorDetails = []
                if (typeof e.response.data === "string" && e.response.data.indexOf("<!DOCTYPE html>") >= 0) {
                    backendError.value = `Une erreur ${e.response.status} est survenue, veuillez réessayer ultérieurement.`
                    errorDetails.push(`Une erreur backend de statut ${e.response.status} est survenue. Plus d'infos dans les outils de monitoring.`)
                }
                else {
                    backendError.value = JSON.stringify(e.response.data)
                    errorDetails.push(backendError.value)
                }
                userStore.trackEvent(
                    'click_sign_up', {
                        'success': false,
                        'page': route.path,
                        'origine': userStore.externalParamsArray[0],
                        'parcours': userStore.externalParamsArray[3],
                        'error_type': 'backend_error',
                        'error_details': JSON.stringify(errorDetails)
                    }
                )
            }
        }
        loadingCreateAccount.value = false
    } else {
        // concatenate all errors in the form
        // let errorDetails = Object.values(errorSet).join(", ")
        let errorDetails = []
        Object.entries(errorSet).forEach(entry => {
            if(entry[1] != null) {
                errorDetails.push(`${entry[0]}: ${entry[1]}`)
            }
        });
        errorDetails = errorDetails.join(", ")
        console.log("frontend_error details:", errorDetails)
        userStore.trackEvent(
            'click_sign_up', {
                'success': false,
                'page': route.path,
                'origine': userStore.externalParamsArray[0],
                'parcours': userStore.externalParamsArray[3],
                'error_type': 'frontend_error',
                'error_details': errorDetails
            }
        )
    }
}

const goToUnfunctionalPrmScreen = () => {
    console.log("goToUnfunctionalPrmScreen -> ", prmFound.value)
    router.push({ name: 'NotFunctionalPrm' })
}

onBeforeMount(() => {
    userStore.trackEvent('PageViewApp')
    if (Object.keys(route.query).length > 0) {
        console.log("Params in query!", route.query)
        userStore.setExternalParams(route.query)
    }
})
</script>

<style scoped>
.my-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 60px 20px;
}

.content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    border-radius: 25px 25px 0 0;
}

.control.has-icons-right .input {
    padding-right: 60px;
}
</style>