<template>
    <div class="my-container">
        <TopCard origin="regular"/>
        <div class="content-container" style="justify-content: space-between;">
            <div>
                <div class="flexcol has-text-primary font-size-20 has-text-centered has-text-weight-bold mb-4 line-height-1-2">
                    <span>Vous avez déjà</span>
                    <span>un compte Watt Watchers ?</span>
                </div>
                <div class="flexcol has-text-primary font-size-14 has-text-centered mb-5 line-height-1-2">
                    <span>Saisissez votre email pour recevoir un lien de connexion.</span>
                </div>
                <!-- Input EMAIL -->
                <div class="control">
                    <input
                        class="input login-v2-input"
                        :class="{'dangerous': errorEmailLogin != null }"
                        v-model="emailLogin"
                        type="text"
                        placeholder="Email"
                        name="email"
                        autocapitalize="off"
                    >
                    <p v-if="errorEmailLogin" class="text-error ml-2 font-size-12 mt-1 line-height-1-1">{{ errorEmailLogin }}</p>
                </div>
            </div>
            <div>
                <!-- Message success -->
                <div v-if="getLinkSuccess" class="text-green-1 has-text-centered line-height-1-2">
                    Un email contenant un lien de connexion à Watt Watchers a été envoyé si un compte lui est relié.
                </div>

                <!-- General errors are displayed here -->
                <div v-if="loginBackendError != null" class="text-error font-size-16 mt-6 has-text-centered line-height-1-1">
                    {{ loginBackendError }}
                </div>

                <!-- Button "Recevoir le lien" -->
                <div style="width: 100%; padding-top: 30px;">
                    <button
                        class="radius-10 height-46 is-fullwidth button is-primary has-text-weight-bold"
                        :class="{ 'is-loading': loadingConnectionLink }"
                        @click="getConnectionLink"
                    >
                        Recevoir le lien
                    </button>
                </div>

                <!-- Button "Retour" -->
                <div
                    class="is-clickable has-text-centered has-text-primary font-size-14 has-text-weight-bold mt-2"
                    style="text-decoration: underline;"
                    @click="resetLogin(); router.push({name: 'SignUp'});"
                >
                    Retour
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/stores/user';
import API from '@/services/api';
import TopCard from '@/components/auth/TopCard.vue'

const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const loadingConnectionLink = ref(false)
const emailLogin = ref(null)
const errorEmailLogin = ref(null)
const getLinkSuccess = ref(false)
const loginBackendError = ref(null)

const resetLogin = () => {
    loadingConnectionLink.value = false
    emailLogin.value = null
    errorEmailLogin.value = null
    getLinkSuccess.value = false
    loginBackendError.value = null
}

const getConnectionLink = async () => {
    loginBackendError.value = null

    if (emailLogin.value == null || emailLogin.value.trim().length < 6) errorEmailLogin.value = "Veuillez mettre un email valide"
    else errorEmailLogin.value = null

    if (errorEmailLogin.value == null && !getLinkSuccess.value) {
        loadingConnectionLink.value = true
        try {
            let resp = await API.getConnectionLink(
                {"email": emailLogin.value}
            )
            if (resp.status == 200) {
                console.log("LINK TREATED", resp.data)
                userStore.trackEvent('click_receive_login', {'page': route.path, 'success': true, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
                getLinkSuccess.value = true
            }
        } catch (e) {
            if (e.response.status == 400) {
                if (Array.isArray(e.response.data)) {
                    loginBackendError.value = e.response.data.join(", ")
                } else {
                    if (e.response.data.email) {
                        errorEmailLogin.value = e.response.data.email.join(" ")
                    }
                }
            }
            else {
                if (Array.isArray(e.response.data)) {
                    loginBackendError.value = e.response.data.join(", ")
                } else {
                    loginBackendError.value = e.response.data
                }
            }
            
            userStore.trackEvent('click_receive_login', {'page': route.path, 'success': false, 'origine': userStore.externalParamsArray[0], 'parcours': userStore.externalParamsArray[3]})
        }
        loadingConnectionLink.value = false
    }
}
</script>

<style scoped>
.my-container {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0 !important;
    height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 60px 20px;
}

.content-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background-color: white;
    border-radius: 25px 25px 0 0;
}
</style>