<template>
    <div class="my-container">
        <div style="padding: 20px;">
            <AlertStack />
            <div class="title-page" style="margin-bottom: 4px;">
                Mes actions
            </div>
            <div v-if="potentialSavings >= 20" style="margin-bottom: 4px; line-height: 20px;">
                <span class="has-text-primary has-text-weight-medium">Nous pouvons vous faire économiser jusqu'à </span>
                <span class="has-text-weight-bold text-blue-2 font-size-20">{{ potentialSavings }}€ par an. </span>
                <span class="has-text-primary has-text-weight-medium">Voici nos recommandations triées par impact.</span>
            </div>
        </div>

        <!-- Recommendations -->
        <div v-if="loaded" class="flexcol" style="position: relative; padding: 0 20px 20px;">
            <div v-if="actionsToDisplay.length > 0" class="flexcol" style="gap: 10px; margin-bottom: 20px;">
                <ActionCard v-for="a in actionsToDisplay" :action=a />
            </div>
            <div v-else class="flexcol has-text-centered px-4 line-height-1-2 text-green-1">
                <span>Bravo !</span>
                <span>Vous avez réalisé toutes les actions recommandées.</span>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { useAlertsStore } from "@/stores/alert";
import { useRoute } from 'vue-router';
import ActionCard from "@/components/actions/ActionCard.vue";
import AlertStack from "@/components/AlertStack.vue";
import API from '@/services/api';
import { ref, onMounted, computed } from 'vue';
import { compareBySavings, compareByEcowatt } from '@/utils.js';

const userStore = useUserStore()
const alertsStore = useAlertsStore()
const route = useRoute()

let allActions = []
const loaded = ref(false)
const isEcoWattDay = ref(false)

const potentialSavings = ref(0)

const actionsToDisplay = computed(() => {
    // filter all actions except not_recommended ones
    let filteredActions = allActions.filter(a => a.recommendation_status != 'not_recommended')
    // compute potentialSavings
    potentialSavings.value = filteredActions.filter(a => a.recommendation_status != 'done').reduce((acc, obj) => acc + Math.ceil(obj.expected_reduction_euros), 0)

    // filter 2 kinds of actions: recommended and doing on one hand and done on the other hand
    let [recommendedAndDoingOnes, doneOnes] = filteredActions.reduce(
        ([recommendedAndDoing, done], obj) => {
            if (obj.recommendation_status == 'recommended' || obj.recommendation_status == 'doing') {
                recommendedAndDoing.push(obj);
            } else {
                done.push(obj);
            }
            return [recommendedAndDoing, done];
        },
        [[], []]
    )

    // sort these 2 arrays by expected_reduction_euros
    recommendedAndDoingOnes = recommendedAndDoingOnes.sort(compareBySavings)
    doneOnes = doneOnes.sort(compareBySavings)
    
    // if it's an ecowatt day, put is_sensitive_to_ecowatt actions first
    if (isEcoWattDay.value) {
        recommendedAndDoingOnes = recommendedAndDoingOnes.sort(compareByEcowatt)
    }
    return [...recommendedAndDoingOnes, ...doneOnes]
})

const getActionsList = async() => {
    let resp = await API.getActionsList()
    if (resp.status == 200) {
        allActions = resp.data
    }
}

const getEcoWattDailyStatus = async() => {
    isEcoWattDay.value = await userStore.get_eco_watt_daily_status().isEcoWattDay
    loaded.value = true
}

onMounted(async() => {
    await getActionsList()
    await getEcoWattDailyStatus()
    alertsStore.sendTracking(route.path)
})
</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 360px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
}
</style>