<template>
    <div v-if="loaded" class="my-container">
        <!-- Blue part -->
        <div class="flexcol bg-blue-5" :style="`margin-bottom: ${potentialSavings >= 20 ? '-145' : ''}px; padding: 20px;`">
            <AlertStack />
            <div class="title-page" style="margin: 12px 0;">
                <span>Bienvenue</span>
                <span>{{ userStore.account.user.first_name }}</span>
            </div>
            <div v-if="userStore.consumedEnergy" class="line-height-1-1 mb-2">
                <span class="has-text-primary has-text-weight-medium">Vous consommez </span>
                <span class="has-text-weight-bold text-blue-2 font-size-20">{{ userStore.consumedEnergy }}€</span>
                <span class="has-text-primary has-text-weight-medium"> d'énergie par an.</span>
            </div>

            <!-- Potential savings -->
            <div v-if="potentialSavings >= 20" class="flexcol" style="align-items: center;">
                <div style="background-image: url('/assets/dashboard/dashboard_white_card.svg'); background-position: center; background-repeat: no-repeat; background-size: contain; min-height: 160px; max-height: 160px; min-width: 330px; max-width: 330px;">
                </div>
                <div style="text-align: center; position: relative; min-height: 160px; max-height: 160px; max-width: 330px; min-width: 330px; transform: translateY(-145px)">
                    <div class="flexcol mt-3" style="gap: 4px;">
                        <span class="has-text-primary has-text-weight-bold">Nous estimons jusqu'à</span>
                        <div class="flexrow" style="justify-content: center;">
                            <span class="text-green-1 has-text-weight-bold" style="font-size: 45px; line-height: 1em;">{{ potentialSavings }}</span>
                            <span class="text-green-1 has-text-weight-bold font-size-20" style=" line-height: 1.3em; align-self: end;">€/an</span>
                        </div>
                        <span class="text-green-1 has-text-weight-medium font-size-14">d'économies possibles</span>
                    </div>
                    <img src="/src/assets/img/info_icon.svg" class="is-clickable" style="position: absolute; right: 25px; top: 10px; height: 23px; width: 23px;" @click="openModalInfoAboutEnergySavings"/>
                    <img src="/src/assets/img/dashboard_stars.svg" style="position: absolute; left: -7px; top: -10px;"/>
                    <img src="/src/assets/img/dashboard_money.svg" style="position: absolute; right: -10px; bottom: 35px;"/>
                </div>
            </div>
        </div>

        <!-- Recommendations -->
        <div class="flexcol bg-white" style="position: relative; padding: 20px;">
            <img v-if="actionsToDisplay.length > 0" src="/src/assets/img/info_icon.svg" class="is-clickable" style="position: absolute; right: 20px; top: 20px; height: 23px; width: 23px;" @click="openModalInfoAboutActions"/>
            
            <span v-if="actionsToDisplay.length > 0" class="has-text-primary font-size-20 has-text-weight-bold line-height-1-2">Voici nos recommandations</span>
            <span v-if="actionsToDisplay.length > 0" class="has-text-primary font-size-20 line-height-1-2">pour optimiser votre facture</span>

            <div v-if="actionsToDisplay.length > 0" class="flexcol" style="gap: 10px; margin: 20px 0;">
                <ActionCard v-for="a in actionsToDisplay" :action=a />
            </div>
            <div v-else>
                <div class="flexcol text-green-1 line-height-1-2 has-text-centered" style="margin-top: 20px;">
                    <span>Bravo&nbsp;!</span>
                    <span>Vous avez réalisé toutes les actions recommandées&nbsp;!</span>
                </div>
                <img src="/src/assets/img/dashboard_high_five.png"/>
            </div>

            <div class="text-blue-2 has-text-weight-semibold line-height-1-2 has-text-centered" style="margin: 20px 0;">
                {{
                    actionsToDisplay.length > 0 ?
                    'Chaque action compte ! Découvrez plus d’actions pour réduire votre facture&nbsp;:' :
                    'Suivez votre consommation énergétique au cours du temps&nbsp;:'
                }}
            </div>

            <div
                class="has-text-white button is-primary height-48 radius-10 has-text-weight-bold mt-0"
                style="width: 100%; margin-bottom: 30px;"
                @click="goToActionsPage(actionsToDisplay.length > 0)">
                {{
                    actionsToDisplay.length > 0 ?
                    "Voir plus d'actions" :
                    "Suivre ma consommation"
                }}
            </div>
        </div>

        <ModalInfoAboutEnergySavings :active="showModalInfoAboutEnergySavings" @toggle-info="showModalInfoAboutEnergySavings = !showModalInfoAboutEnergySavings"/>
        <ModalInfoAboutActions :active="showModalInfoAboutActions" @toggle-info="showModalInfoAboutActions = !showModalInfoAboutActions"/>
    </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { useAlertsStore } from "@/stores/alert";
import { useRouter, useRoute } from "vue-router";
import ActionCard from "@/components/actions/ActionCard.vue";
import AlertStack from "@/components/AlertStack.vue";
import ModalInfoAboutEnergySavings from "@/components/modals/ModalInfoAboutEnergySavings.vue";
import ModalInfoAboutActions from "@/components/modals/ModalInfoAboutActions.vue";
import API from '@/services/api';
import { ref, onMounted, computed } from 'vue';
import { compareBySavings, compareByEcowatt } from '@/utils.js';

const userStore = useUserStore()
const alertsStore = useAlertsStore()
const router = useRouter()
const route = useRoute()

let allActions = []
const loaded = ref(false)
const isEcoWattDay = ref(false)

const potentialSavings = ref(0)
const showModalInfoAboutEnergySavings = ref(false)
const showModalInfoAboutActions = ref(false)


const openModalInfoAboutEnergySavings = () => {
    showModalInfoAboutEnergySavings.value = true
    userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'dashboard_savings'})
}

const openModalInfoAboutActions = () => {
    showModalInfoAboutActions.value = true
    userStore.trackEvent('click_tooltip', {'page': route.path, 'tooltip_name': 'dashboard_actions'})
}

const goToActionsPage = (toAction) => {
    if(toAction) {
        userStore.trackEvent('click_see_actions', {'page': route.path})
        router.push({name: 'ActionsList'})
    } else {
        userStore.trackEvent('click_see_conso_no_actions', {'page': route.path})
        router.push({name: 'Conso'})
    }
}

const actionsToDisplay = computed(() => {
    // filter recommended and doing actions
    let filteredActions = allActions.filter(a => a.recommendation_status == 'recommended' || a.recommendation_status == 'doing').sort(compareBySavings)
    // compute potentialSavings
    potentialSavings.value = filteredActions.reduce((acc, obj) => acc + Math.ceil(obj.expected_reduction_euros), 0)

    // if it's an ecowatt day, put is_sensitive_to_ecowatt actions first
    if (isEcoWattDay.value) {
        filteredActions = filteredActions.sort(compareByEcowatt)
    }

    // return just the first 3 ones
    return filteredActions.slice(0, 3)
})

const getActionsList = async() => {
    let resp = await API.getActionsList()
    if (resp.status == 200) {
        allActions = resp.data
    }
}

const getEcoWattDailyStatus = async() => {
    isEcoWattDay.value = await userStore.get_eco_watt_daily_status().isEcoWattDay
    loaded.value = true
}

onMounted(async() => {
    await getActionsList()
    await getEcoWattDailyStatus()
    alertsStore.sendTracking(route.path)
})
</script>

<style scoped>
.my-container {
    max-width: 600px;
    min-width: 360px;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-bottom: 80px;
}
</style>