import axios from 'axios'

// BACKEND_ROOT is defnined in vite.config.js

const getCookie = function (name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const myProfile = async function () {
    let url = `${BACKEND_ROOT}/accounts/my-profile/`
    let res = await axios.get(url)
    console.log('MY PROFILE', res)
    return res
}

const login = async function (user, pass) {
    let res = await axios.post(`${BACKEND_ROOT}/login/token/`, {
        username: user,
        password: pass,
    })
    return res
}

const passwordLost = async function (email) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/password-reset/`, {
        email: email,
    })
    return res
}

const deprecated_signUp = async function (email, pass, pass2, cgu, optin) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/`, {
        email: email,
        password: pass,
        password2: pass2,
        cgu: cgu,
        optin: optin
    })
    return res
}

const createAccount = async function (
    lastname,
    firstname,
    email,
    addressName,
    addressCityCode,
    addressPostCode,
    cgu,
    consent,
    prm,
    fullname,
    process,
    origin,
    utm,
    partner
) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/`, {
        lastname: lastname,
        firstname: firstname,
        email: email,
        address_name: addressName,
        address_city_code: addressCityCode,
        address_post_code: addressPostCode,
        cgu: cgu,
        consent: consent,
        prm: prm,
        fullname: fullname,
        process: process,
        origin: origin,
        utm: utm,
        partner: partner
    })
    return res
}

const setPrmAndOptins = async function (account_id, cgu, consent, prm, fullname) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${account_id}/set-prm-and-optins/`, {
        cgu: cgu,
        consent: consent,
        prm: prm,
        fullname: fullname,
    })
    return res
}

const getIndicators = async function (account_id, periodType, minPeriodDate, maxPeriodDate) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${account_id}/indicators/`, {
        period_type: periodType,
        min_period_date: minPeriodDate,
        max_period_date: maxPeriodDate

    })
    console.log("daily_indicators")
    return res
}

const getCgu = async function () {
    let res = await axios.get(`${BACKEND_ROOT}/cgu/`, {})
    return res
}

const updatePassword = async function (pass1, pass2, uib64, token) {
    const formData = new FormData();
    formData.append("new_password1", pass1);
    formData.append("new_password2", pass2);
    formData.append("token", token);
    let res = await axios.post(
        `${BACKEND_ROOT}/accounts/reset_custom/${uib64}/set-password/`, formData)
    return res
}

const deprecated_searchEnedisPrm = async function (account_id, first_name, last_name, address, insee, zipcode) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${account_id}/search-enedis-prm/`, {
        first_name: first_name,
        last_name: last_name,
        address: address,
        insee: insee,
        zipcode: zipcode
    })
    return res
}

const searchEnedisPrm = async function (firstname, lastname, address, insee, zipcode) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/search-enedis-prm/`, {
        first_name: firstname,
        last_name: lastname,
        address: address,
        insee: insee,
        zipcode: zipcode
    })
    return res
}

const searchZipCode = async function (zipcode) {
    let res = await axios.post(`${BACKEND_ROOT}/city/search/`, {
        zipcode: zipcode
    })
    return res
}

const setEnedisPrm = async function (accountId, prmData) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/set-enedis-prm/`, prmData)
    return res
}

const pushQuizData = async function (accountId, payload) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/push-quiz-data/`, {
        quiz_data: payload
    })
    return res
}

const pushSurveyData = async function (accountId, payload) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/push-survey-data/`, {
        survey_data: payload
    })
    return res
}

const endingSurveyData = async function (accountId, payload) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/ending-survey-data/`)
    return res
}

const setOnBoardingDone = async function (accountId, payload) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/on-boarding-done/`, payload)
    return res
}

const getProviders = async function () {
    let res = await axios.get(`${BACKEND_ROOT}/provider/`)
    return res
}

const verifyAndSelectPrm = async function (accountId, payload) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/verify-and-select-prm/`, payload)
    return res
}

const giveConsent = async function (accountId, payload) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/give-consent/`, payload)
    return res
}

const verifyEmail = async function (uib64, token) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/validate-email/`, {
        token: token,
        uidb64: uib64
    })
    return res
}

const getConnectionLink = async function (payload) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/get-connection-link/`, payload)
    return res
}

const checkConnectionLink = async function (uib64, token) {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/check-connection-link/`, {
        token: token,
        uidb64: uib64
    })
    return res
}

const searchAddressFromBan = async function (terms) {
    let res = await axios.get("https://api-adresse.data.gouv.fr/search/", {
        params: {
            q: terms
        }
    })
    return res
}

const saveCustomProviderPrices = async function (accountId, payload) {
    let res = axios.post(`${BACKEND_ROOT}/accounts/${accountId}/save-custom-provider-prices/`, payload)
    return res
}

const getEcoWattDailyStatus = async function () {
    let res = await axios.get(`${BACKEND_ROOT}/ecowattsignal/get-eco-watt-daily-status/`, {})
    return res
}

const sendToAnalytics = async function(payload) {
    let res = axios.post(`${BACKEND_ROOT}/monitor/log/`, payload)
    return res
}

const getActionsList = async function() {
    let res = await axios.get(`${BACKEND_ROOT}/action/`)
    return res
}

const updateActionState = async function(actionId, payload) {
    let res = await axios.put(`${BACKEND_ROOT}/action/${actionId}/`, payload)
    return res
}

const getAction = async function(actionId) {
    let res = await axios.get(`${BACKEND_ROOT}/action/${actionId}/`)
    return res
}

const getThermostatDevicesList = async function() {
    let res = await axios.get(`${BACKEND_ROOT}/thermostat-device/`)
    return res
}

const updateThermostatDevice = async function(deviceId, payload) {
    let res = await axios.put(`${BACKEND_ROOT}/thermostat-device/${deviceId}/`, payload)
    return res
}

const updateThermostatDeviceMode = async function(deviceId, payload) {
    let res = await axios.put(`${BACKEND_ROOT}/thermostat-device/${deviceId}/update-mode/`, payload)
    return res
}

const getThermostatProfilesList = async function() {
    let res = await axios.get(`${BACKEND_ROOT}/thermostat-profile/`)
    return res
}

const createThermostatProfile = async function(payload) {
    let res = await axios.post(`${BACKEND_ROOT}/thermostat-profile/`, payload)
    return res
}

const updateThermostatProfile = async function(profileId, payload) {
    let res = await axios.put(`${BACKEND_ROOT}/thermostat-profile/${profileId}/`, payload)
    return res
}

const deleteThermostatProfile = async function(profileId) {
    let res = await axios.delete(`${BACKEND_ROOT}/thermostat-profile/${profileId}/`)
    return res
}

const getThermostatErasuresList = async function() {
    let res = await axios.get(`${BACKEND_ROOT}/thermostat-erasure/`)
    return res
}

const cancelThermostatErasureParticipation = async function(erasureId) {
    let res = await axios.post(`${BACKEND_ROOT}/thermostat-erasure/${erasureId}/cancel-participation/`)
    return res
}

const addUserOnThermostatBrevoList = async (accountId) => {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/update-thermostat-users-list/`)
    return res
}

const endingGasSignup = async (accountId, payload) => {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/ending-gas-signup/`, payload)
    return res
}

const grantingGrdf = async (accountId, payload) => {
    let res = await axios.post(`${BACKEND_ROOT}/accounts/${accountId}/granting-grdf/`, payload)
    return res
}

export default {
    myProfile,
    login,
    passwordLost,
    createAccount,
    setPrmAndOptins,
    searchEnedisPrm,
    getCgu,
    updatePassword,
    searchZipCode,
    setEnedisPrm,
    pushQuizData,
    pushSurveyData,
    setOnBoardingDone,
    getProviders,
    verifyAndSelectPrm,
    verifyEmail,
    searchAddressFromBan,
    giveConsent,
    getIndicators,
    saveCustomProviderPrices,
    getEcoWattDailyStatus,
    sendToAnalytics,
    getActionsList,
    updateActionState,
    endingSurveyData,
    getAction,
    getThermostatDevicesList,
    updateThermostatDevice,
    updateThermostatDeviceMode,
    getThermostatProfilesList,
    createThermostatProfile,
    updateThermostatProfile,
    deleteThermostatProfile,
    getThermostatErasuresList,
    cancelThermostatErasureParticipation,
    getConnectionLink,
    checkConnectionLink,
    addUserOnThermostatBrevoList,
    endingGasSignup,
    grantingGrdf,
}
