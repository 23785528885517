<template>
    <div class="flexcol" style="height: 100vh;">
        <iframe
            :src="formUrl"
            width="100%"
            height="100%"
            frameborder="0"
        >
        </iframe>
    </div>
    
</template>

<script setup>
import { computed } from 'vue';
import { useUserStore } from '@/stores/user';

const userStore = useUserStore()

// Embedded Typeform for Connected Thermostat survey
const formUrl = computed(() => {
    let email = userStore.account.user.username
    let firstName = userStore.account.enedis_first_name
    let lastName = userStore.account.enedis_last_name
    let address = userStore.account.enedis_address
    let zipcode = userStore.account.enedis_zipcode
    let city = userStore.account.enedis_city_name
    let source = userStore.account.utm != null && Object.keys(userStore.account.utm).includes('utm_source') ? userStore.account.utm['utm_source'] : 'null'
    let prm = userStore.account.enedis_prm
    let data = `email=${email}&prenom=${firstName}&nom=${lastName}&adresse=${address}&codepostal=${zipcode}&ville=${city}&source=${source}&prm=${prm}`
    console.log("url", "https://winter-energies.typeform.com/to/gsWXTOxb#" + data)
    return `https://winter-energies.typeform.com/to/gsWXTOxb#${data}`
})


</script>